<template>
  <section id="fondoVideo">
    <video v-if="soportado" autoplay muted loop id="bgVideo">
      <source :src="videoURL" type="video/webm" />
    </video>
    <picture v-else>
      <source
        media="(min-width: 1280px)"
        :srcset="datosVideo.poster.pc.webp"
        type="image/webp"
      />
      <source
        media="(min-width: 1280px)"
        :srcset="datosVideo.poster.pc.default"
        type="image/jpeg"
      />
      <source
        :srcset="datosVideo.poster.movil.webp"
        type="image/webp"
      />
      <img
        width="1125"
        height="2436"
        class="imagen_fondo"
        :src="datosVideo.poster.movil.default"
        alt="Imagen de hojas de fondo"
      />
    </picture>
  </section>
</template>

<script>
import { ref } from "@vue/reactivity";
export default {
  name: "fondoVideo",
  props: {
    datosVideo: Object,
  },
  setup(props) {
    const datosVideo = props.datosVideo
    const soportado = ref(true);
    var videoURL = ref(
      window.innerHeight > window.innerWidth
        ? datosVideo.url_video.vertical
        : datosVideo.url_video.horizontal
    );
    return { datosVideo, soportado, videoURL };
  },
  created() {
    window.addEventListener("resize", this.recargarVideo);
  },
  mounted() {
    this.soportaVideo();
  },
  methods: {
    soportaVideo() {
      var objVideo = document.getElementById("bgVideo");
      if (objVideo.canPlayType("video/webm") === "") this.soportado = false;
    },
    recargarVideo() {
      this.videoURL =
        window.innerHeight > window.innerWidth
        ? this.datosVideo.url_video.vertical
        : this.datosVideo.url_video.horizontal
      document.getElementById("bgVideo").load();
    },
  },
};
</script>

<style>
</style>